import React, { FC } from "react"

export interface CartSummaryProps {
  subtotal: string
  total: string
  discountApplications: any
  shipping?: string
  taxes: string
  savings?: string
  handleCheckout: any
  loading: boolean
}

const CartSummary: FC<CartSummaryProps> = ({
  subtotal,
  total,
  shipping = "Calculated at checkout",
  taxes,
  savings = "",
  handleCheckout,
  loading,
}) => {
  return (
    <div
      className="rounded-lg p-8 sticky top-32"
      style={{ background: "#F9F7F5", color: "#5F5451" }}
    >
      <h3 className="font-bold text-lg mb-4" style={{ color: "#3F3836" }}>
        Order Summary
      </h3>
      <div className="grid grid-cols-2 gap-4 text-sm pb-6 border-b-2 border-[#49391F10]">
        <p>Item Subtotal</p>
        <p className="text-right">{subtotal}</p>

        {savings && (
          <>
            <p className="text-idc-orange">Savings</p>
            <p className="text-right text-idc-orange">{savings}</p>
          </>
        )}

        <p>Shipping</p>
        <p className="text-right">{shipping}</p>

        <p>Taxes</p>
        <p className="text-right">{taxes}</p>
      </div>

      <div
        className="grid grid-cols-2 text-sm mt-6 mb-8"
        style={{ color: "#3F3836" }}
      >
        <p className="font-semibold">Total</p>
        <p className="font-bold text-right" style={{ fontSize: "22px" }}>
          {total}
        </p>
      </div>
      <button
        onClick={handleCheckout}
        //set loading to true when button is clicked
        disabled={loading}
        className="w-full h-14 mb-4 font-bold text-lg text-center rounded-full text-white bg-idc-orange hover:bg-idc-blue hover:bg-opacity-75 active:bg-idc-blue transition duration-300 ease-in-out"
      >
        {!loading && <>Continue to checkout &nbsp;</>}

        {loading && (
          <>
            {" "}
            <span className="inline-block">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
                  fill="#fff"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    dur="0.75s"
                    values="0 12 12;360 12 12"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </span>
          </>
        )}
      </button>
      <p className="text-xs text-center">
        Discount and promotional codes can be added during checkout.
      </p>
    </div>
  )
}

export default CartSummary
