import React, { useState } from "react"
import { Link, graphql, navigate } from "gatsby"
import { Layout } from "components/layouts/Layout/layout"
import CartSummary from "components/elements/CartSummary"
import { StoreContext } from "context/store-context"
import LineItem from "components/elements/LineItem"
import { formatPrice } from "utils/format-price"
import ProductListing from "components/layouts/ProductListing"
// import { getListItems } from "services/wishlist"
import { Seo } from "components/seo"
import { isLoggedIn, getUser, getMultipassUrl } from "services/auth"
import LoginModal from "components/layouts/Account/LoginModal"
// import { eligibleForGift } from "utils/freeGifts"
// import FreeGifts from "components/elements/FreeGifts"
// import "services/mulberry"
import "./cart.css"
import Markdown from "markdown-to-jsx"
import { FreeGiftMessageItem } from "../components/elements/FreeGiftMessageItem/FreeGiftMessageItem"

export default function CartPage({
  data: { cartDisclaimers, suggestions, freeGifts },
}) {
  // export default function CartPage({ data: { cartDisclaimers, suggestions } }) {
  const noteRef = React.createRef()
  const { checkout, updateCartAttributes } = React.useContext(StoreContext)
  // const { client, checkout, loading, updateCartAttributes } =
  // React.useContext(StoreContext)
  const [isLoading, setIsLoading] = useState(false)
  const [saveForLaterItems] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  // const [freeGiftRule, setFreeGiftRule] = useState(null)
  // const [freeGiftChoices, setFreeGiftChoices] = useState([])

  const sendViewCartGTMEvent = () => {
    if (typeof window !== "undefined" && window.dataLayer) {
      const amount = parseFloat(
        checkout.lineItems.length ? checkout.totalPrice.amount : 0
      )
      window.dataLayer.push({
        event: "view_cart",
        currency: checkout?.currencyCode || "CAD",
        value: amount,
        items: checkout.lineItems.map((item) => {
          const productGidSplitArray = item.variant.product.id.split("/")
          const varantGidSplitArray = item.variant.id.split("/")

          const parentProductId =
            productGidSplitArray[[productGidSplitArray.length - 1]]
          const variantId =
            varantGidSplitArray[[varantGidSplitArray.length - 1]]
          const merchantID = `shopify_CA_${parentProductId}_${variantId}`
          return {
            item_name: item.title,
            item_variant: item.variant.title,
            price: parseFloat(item.variant.price.amount),
            item_id: merchantID,
          }
        }),
      })
    }
  }

  React.useEffect(() => {
    sendViewCartGTMEvent()
  }, [])

  const emptyCart = checkout.lineItems.length === 0
  const recommendedProductsData = {
    products: suggestions.nodes.map((product) => {
      return {
        ...product,
        productImage: product.images?.[0],
      }
    }),
    scrollable: suggestions.nodes.length > 3,
    listingDetails: {
      title: "You May Also Need",
      linkBottom: false,
    },
  }

  const discountApplications =
    checkout?.discountApplications?.length > 0
      ? checkout.discountApplications.map((discount) => {
          let disocuntDetails = ""

          if (discount?.value?.percentage) {
            disocuntDetails = `${discount.value.percentage}% Off`
          }
          if (discount?.value?.amount) {
            disocuntDetails = `-${formatPrice(
              discount.value.currencyCode,
              discount.value.amount
            )}`
          }

          return {
            code: discount.code,
            applicable: discount.applicable,
            value: discount.value,
            details: disocuntDetails,
          }
        })
      : []

  const addCartNote = async () => {
    //construct the array
    let customAttributesList = []
    //first, we will tackle v
    // if (localStorage.getItem("gclid")) {
    //   customAttributesList.push({
    //     key: "gclid",
    //     value: localStorage.getItem("gclid"),
    //   })
    // }

    // if (document.cookie.includes("shopify_checkout_id")) {
    //   customAttributesList.push({
    //     key: "client_id",
    //     value: document.cookie.split("shopify_checkout_id=")[1].split(";")[0],
    //   })
    // }

    // if (localStorage.getItem("referrer")) {
    //   customAttributesList.push({
    //     key: "referrer",
    //     value: localStorage.getItem("referrer"),
    //   })
    // }

    // if (document.cookie.includes("_ga4sid")) {
    //   customAttributesList.push({
    //     key: "session_id",
    //     value: document.cookie.split("_ga4sid=")[1].split(";")[0],
    //   })
    // }
    // if (document.cookie.includes("_ga_4X6PJK00XD")) {
    //   customAttributesList.push({
    //     key: "ga_4X6PJK00XD",
    //     value: document.cookie.split("_ga_4X6PJK00XD=")[1].split(";")[0],
    //   })
    // }
    // if (document.cookie.includes("_landing_page")) {
    //   customAttributesList.push({
    //     key: "landing_page",
    //     value: document.cookie.split("_landing_page=")[1].split(";")[0],
    //   })
    // }

    // if (checkout.id) {
    //   customAttributesList.push({
    //     key: "checkout_id",
    //     value: checkout.id,
    //   })
    // }

    const updatedCheckout = await updateCartAttributes(checkout.id, {
      note: noteRef.current.value,
      customAttributes:
        customAttributesList?.length > 0 ? customAttributesList : null,
    })

    return updatedCheckout
  }

  const transformShopifyCheckoutUrlToIDCSubDomain = (shopifyCheckoutUrl) => {
    return shopifyCheckoutUrl.replace(
      "https://idrinkcoffee.myshopify.com/",
      "https://checkout.idrinkcoffee.com/"
    )
  }

  const sendGTMBeginCheckout = () => {
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "begin_checkout",
        currency: checkout.currencyCode,
        value: parseFloat(checkout.totalPrice.amount),
        ecommerce: {
          items: [
            checkout.lineItems.map((item) => {
              const productGidSplitArray = item.variant.product.id.split("/")
              const varantGidSplitArray = item.variant.id.split("/")

              const parentProductId =
                productGidSplitArray[[productGidSplitArray.length - 1]]
              const variantId =
                varantGidSplitArray[[varantGidSplitArray.length - 1]]
              const merchantID = `shopify_CA_${parentProductId}_${variantId}`

              return {
                item_name: item.title,
                item_variant: item.variant.title,
                price: parseFloat(item.variant.price.amount),
                item_id: merchantID,
                id: merchantID,
                quantity: item.quantity,
                google_business_vertical: "retail",
              }
            }),
          ],
        },
      })
    }
  }

  const handleCheckout = async () => {
    sendGTMBeginCheckout()
    const updatedCheckout = await addCartNote()

    if (isLoggedIn()) {
      console.log("Is logged in")
      setIsLoading(true)
      const user = getUser()
      const multipass = await getMultipassUrl(
        user.email,
        user.accessToken,
        updatedCheckout.webUrl
      )
        .then((res) => {
          console.log(
            "getMultipassUrl success, res:",
            res,
            "user:",
            user,
            "updatedCheckout:",
            updatedCheckout
          )
          if (res?.multipassURL) {
            return navigate(
              res.multipassURL + "?skip_shopify_pay=false",
              "_self"
            )
          } else {
            return navigate(
              transformShopifyCheckoutUrlToIDCSubDomain(
                updatedCheckout.webUrl
              ) + "?skip_shopify_pay=false",
              "_self"
            )
          }
        })
        .catch((err) =>
          console.warn(
            "getMultipassUrl, error:",
            err,
            res,
            "user:",
            user,
            "updatedCheckout:",
            updatedCheckout
          )
        )
    } else {
      return navigate(
        transformShopifyCheckoutUrlToIDCSubDomain(updatedCheckout.webUrl) +
          "?skip_shopify_pay=false",
        "_self"
      )
    }
  }

  let freeGiftMessages = []
  checkout.lineItems.forEach((item) => {
    item.customAttributes.forEach((attribute) => {
      if (attribute.key === "FreeGiftMessages") {
        freeGiftMessages.push(...JSON.parse(attribute.value))
      }
    })
  })

  const removeDuplicatesFromArray = (array) => {
    return array.filter((item, index) => array.indexOf(item) === index)
  }

  return (
    <Layout>
      <div className="mx-4 mt-6 lg:mt-14 sm:mx-6 xl:mx-28 xl:mr-20">
        {emptyCart ? (
          <div className="mx-auto cart-page">
            <h1 className="mb-14 text-3xl font-bold">Your cart is empty</h1>
            <p>
              Looks like you haven’t found anything yet. We understand that
              sometimes it’s hard to choose — maybe this helps:
            </p>
            <Link
              to="/search/?search=coffee"
              className="text-idc-blue font-semibold underline hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
            >
              View coffee products
            </Link>
          </div>
        ) : (
          <>
            <div className="mx-auto mb-12 cart-page lg:mb-20">
              <LoginModal
                isVisible={modalOpen}
                setIsVisible={setModalOpen}
                title="Login to save items"
              />
              <h1 className="mb-14 text-3xl font-bold text-idc-title">Cart</h1>
              <div className="grid gap-8 mb-16 cart-grid">
                <div>
                  {checkout.lineItems.map((item) => (
                    <div
                      className="cart-item py-8 first:pt-0 last:pb-0 border-b border-[#F4F3F2] last:border-none"
                      key={item.id}
                    >
                      <LineItem
                        item={item}
                        saveForLaterItems={saveForLaterItems}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setModalOpen={setModalOpen}
                      />
                    </div>
                  ))}
                  <div className="flex justify-between items-center">
                    {freeGifts.offers.length > 0 &&
                      freeGifts.offers.slice(0, 1).map(() => {
                        // if priority is greater and an offer matches the cart value, we will just show the offer with the bigger priority
                        if (
                          checkout.subtotalPriceV2.amount >=
                            freeGifts.offers[0].cartValue &&
                          freeGifts.offers[0].active === true &&
                          freeGifts.offers[1].cartValue >
                            checkout.subtotalPriceV2.amount
                        ) {
                          return (
                            <div className="w-full">
                              <div
                                className="border border-[#F4F3F2] rounded-md p-6"
                                key={freeGifts.offers[0].id}
                              >
                                <div className="flex justify-between items-center mb-4">
                                  <h3 className="text-xl font-bold">
                                    {freeGifts.offers[0].title}
                                  </h3>
                                </div>
                                <p className="mb-4 font-semibold text-idc-blue">
                                  <Markdown>
                                    {freeGifts.offers[0].description}
                                  </Markdown>
                                </p>
                                <div className="flex justify-between items-center">
                                  {freeGifts.offers[0].cartValue && (
                                    <p className="font-semibold text-idc-blue">
                                      {" "}
                                      Free with orders over
                                      {" " +
                                        formatPrice(
                                          checkout.subtotalPriceV2.currencyCode,
                                          freeGifts.offers[0].cartValue
                                        )}
                                    </p>
                                  )}
                                  <p className="text-sm">
                                    The complimentary item will automatically be
                                    added to your order after it is placed
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })}

                    {freeGifts.offers.length > 0 &&
                      freeGifts.offers.slice(0, 1).map(() => {
                        // if priority is greater and an offer matches the cart value, we will just show the offer with the bigger priority
                        if (
                          checkout.subtotalPriceV2.amount >=
                            freeGifts.offers[1].cartValue &&
                          freeGifts.offers[1].active === true
                        ) {
                          return (
                            <div className="w-full">
                              <div
                                className="border border-[#F4F3F2] rounded-md p-6"
                                key={freeGifts.offers[1].id}
                              >
                                <div className="flex justify-between items-center mb-4">
                                  <h3 className="text-xl font-bold">
                                    {freeGifts.offers[1].title}
                                  </h3>
                                </div>
                                <p className="mb-4 font-semibold text-idc-blue">
                                  <Markdown>
                                    {freeGifts.offers[1].description}
                                  </Markdown>
                                </p>
                                <div className="flex justify-between items-center">
                                  {freeGifts.offers[1].cartValue && (
                                    <p className="font-semibold text-idc-blue">
                                      {" "}
                                      Free with orders over
                                      {" " +
                                        formatPrice(
                                          checkout.subtotalPriceV2.currencyCode,
                                          freeGifts.offers[1].cartValue
                                        )}
                                    </p>
                                  )}
                                  <p className="text-sm">
                                    The complimentary item will automatically be
                                    added to your order after it is placed
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })}
                  </div>
                  {/* Free gift messages */}
                  <div className="flex flex-col">
                    {removeDuplicatesFromArray(freeGiftMessages)
                      .map((_, index) => (
                        <>
                          <FreeGiftMessageItem idx={index} />
                        </>
                      ))
                      ?.at(-1)}
                  </div>

                  <textarea
                    rows="5"
                    className="block px-4 py-3 my-8 w-full font-medium rounded-md border border-gray-300 text-idc-blue focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                    type="text"
                    id="cart-note"
                    placeholder="Add a cart note"
                    ref={noteRef}
                  />
                </div>

                <div>
                  <CartSummary
                    loading={isLoading}
                    handleCheckout={handleCheckout}
                    discountApplications={discountApplications}
                    subtotal={formatPrice(
                      checkout.subtotalPriceV2.currencyCode,
                      checkout.subtotalPriceV2.amount
                    )}
                    taxes={"Calculated at checkout"}
                    total={formatPrice(
                      checkout.subtotalPriceV2.currencyCode,
                      checkout.subtotalPriceV2.amount
                    )}
                  />
                </div>
              </div>{" "}
              <ProductListing
                isCart={true}
                columns={5}
                {...recommendedProductsData}
              />
              {cartDisclaimers?.disclaimer ? (
                <div className="grid gap-10 mt-10 lg:mt-16 md:grid-cols-2 lg:grid-cols-3 xl:gap-x-16">
                  {cartDisclaimers.disclaimer.map((disclaimer) => (
                    <div className="max-w-[23rem]" key={disclaimer.id}>
                      <h3 className="mb-2 text-lg font-bold text-idc-title">
                        {disclaimer.title}
                      </h3>
                      <p className="mb-2 text-sm font-medium text-idc-body">
                        {disclaimer.headline}
                      </p>
                      <p className="text-idc-body text-[0.8125rem] mb-3">
                        {disclaimer.description}
                      </p>
                      {disclaimer?.page && (
                        <Link
                          to={`/${disclaimer.page.handle}`}
                          className="font-semibold text-idc-blue underline hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
                        >
                          Learn More
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export const Head = () => {
  return <Seo title="Cart" />
}

// set the cookie "shopify_pay_redirect=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;" so that it doesn't automatically redirect to the checkout page after the user logs in
export const getServerSideProps = async ({ req, res }) => {
  const cookie = req.headers.cookie
  if (cookie) {
    res.setHeader(
      "Set-Cookie",
      `shopify_pay_redirect=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    )
  }
  return {
    props: {},
  }
}

export const query = graphql`
  query {
    cartDisclaimers: strapiCartDisclaimers {
      disclaimer {
        id
        title
        headline
        description
        page {
          handle
        }
      }
    }
    suggestions: allShopifyProduct(limit: 3) {
      nodes {
        ...ProductCard
      }
    }
    freeGifts: strapiFreeGifts {
      id
      offers {
        active
        cartSize
        cartValue
        code
        eligibleCollectionIds
        giftItemLimit
        endDate
        giftProductIds
        id
        offerCriteria
        priority
        startDate
        title
        description
      }
      strapiId
      updated_at
      published_at
    }
    bonusPacks: allBonusPackagesJson {
      edges {
        node {
          id
          bonusVariantId
          value
          message
          image {
            src
            alt
          }
        }
      }
    }
  }
`
