// const data = JSON.stringify({
//   "title": "Breville Barista Touch BES880BSS Espresso Machine - Brushed Stainless Steel",
//   "id": "30325694070818",
//   "price": "1359.99",
//   "url": "https://yoursite.com/this/product",
//   "description": "In the world of coffee, freshness can be measured in seconds. By the time even five minutes has passed, ground beans have started to lose their flavor. Grinding beans every time you want fresh espresso takes time, though, and oftentimes it becomes a tradeoff between taste and convenience. The Breville Barista Touch solves this problem, delivering fresh, quality espresso at remarkable speed, thanks to its integrated burr grinder. Go from beans to espresso in under a minuteall in the comfort of your own home.",
//   "meta": {
//     "breadcrumbs": [
//       {
//         "category": "Espresso Machines",
//         "url": "https://idrinkcoffee.com//collections/types?q=Espresso%20Machines"
//       }
//     ]
//   },
//   "images": [
//     {
//       "src": "https://cdn.shopify.com/s/files/1/1201/3604/products/espresso-machines-breville-barista-express-espresso-machine-870xl-1_1200x1200.jpg?v=1553449623"
//     },
//     {
//       "src": "https://cdn.shopify.com/s/files/1/1201/3604/products/espresso-machines-breville-barista-express-espresso-machine-870xl-3_1200x1200.jpg?v=1553449623"
//     }
//   ]
// });

// fetch('https://partner.getmulberry.com/api/warranty-offers', {
//   method: 'POST',
//   headers: {
//     'Content-Type': 'application/json',
//     'authorization': 'Bearer UnM_WIsY2T6yRN-JtFyzMds9y3Y',
//     'Access-Control-Allow-Origin': '*'
//   },
//   body: data
// }).then(response => response.json()).then(r => {
//   console.log(r[0]);
// });

export const priceBands = [
  {
    sku: "warranty-4cf0904c9918",
    minCost: 50,
    maxCost: 99.99,
    warrantyCost: 10.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323031765026",
  },
  {
    sku: "warranty-da64314fb454",
    minCost: 50,
    maxCost: 99.99,
    warrantyCost: 12.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032387618",
  },
  {
    sku: "warranty-6ae6de45ad85",
    minCost: 100,
    maxCost: 149.99,
    warrantyCost: 17.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323031797794",
  },
  {
    sku: "warranty-9dd7e7e54207",
    minCost: 100,
    maxCost: 149.99,
    warrantyCost: 21.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032420386",
  },
  {
    sku: "warranty-864877f70ab9",
    minCost: 150,
    maxCost: 199.99,
    warrantyCost: 24.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323031830562",
  },
  {
    sku: "warranty-7d0886243fe4",
    minCost: 150,
    maxCost: 199.99,
    warrantyCost: 29.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032453154",
  },
  {
    sku: "warranty-2d31daeb1322",
    minCost: 200,
    maxCost: 249.99,
    warrantyCost: 31.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323031863330",
  },
  {
    sku: "warranty-3e5c54a2f404",
    minCost: 200,
    maxCost: 249.99,
    warrantyCost: 38.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032485922",
  },
  {
    sku: "warranty-446e177406cf",
    minCost: 250,
    maxCost: 299.99,
    warrantyCost: 34.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323031896098",
  },
  {
    sku: "warranty-ef28c4cbc9c9",
    minCost: 250,
    maxCost: 299.99,
    warrantyCost: 42.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032518690",
  },
  {
    sku: "warranty-ba260d1fc0cc",
    minCost: 300,
    maxCost: 399.99,
    warrantyCost: 50.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323031928866",
  },
  {
    sku: "warranty-c8a680dcf4bc",
    minCost: 300,
    maxCost: 399.99,
    warrantyCost: 62.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032551458",
  },
  {
    sku: "warranty-5dd10f15a46b",
    minCost: 400,
    maxCost: 499.99,
    warrantyCost: 65.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323031961634",
  },
  {
    sku: "warranty-ed7c7dfbd085",
    minCost: 400,
    maxCost: 499.99,
    warrantyCost: 81.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032584226",
  },
  {
    sku: "warranty-00c3db97ba41",
    minCost: 500,
    maxCost: 749.99,
    warrantyCost: 88.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323031994402",
  },
  {
    sku: "warranty-b20ffaba0232",
    minCost: 500,
    maxCost: 749.99,
    warrantyCost: 110.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032616994",
  },
  {
    sku: "warranty-67f302715e32",
    minCost: 750,
    maxCost: 999.99,
    warrantyCost: 108.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323032027170",
  },
  {
    sku: "warranty-0c18d6e09930",
    minCost: 750,
    maxCost: 999.99,
    warrantyCost: 135.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032649762",
  },
  {
    sku: "warranty-937c94c041ce",
    minCost: 1000,
    maxCost: 1499.99,
    warrantyCost: 149.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323032059938",
  },
  {
    sku: "warranty-76fd73abcd4a",
    minCost: 1000,
    maxCost: 1499.99,
    warrantyCost: 185.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032682530",
  },
  {
    sku: "warranty-30978f959199",
    minCost: 1500,
    maxCost: 1999.99,
    warrantyCost: 183.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323032092706",
  },
  {
    sku: "warranty-21404c4bedec",
    minCost: 1500,
    maxCost: 1999.99,
    warrantyCost: 194.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032715298",
  },
  {
    sku: "warranty-fe0e12400e36",
    minCost: 2000,
    maxCost: 2499.99,
    warrantyCost: 209.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323032125474",
  },
  {
    sku: "warranty-bc44bdf31220",
    minCost: 2000,
    maxCost: 2499.99,
    warrantyCost: 219.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032748066",
  },
  {
    sku: "warranty-08290c74153e",
    minCost: 2500,
    maxCost: 2999.99,
    warrantyCost: 239.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323032158242",
  },
  {
    sku: "warranty-63f329ee228d",
    minCost: 2500,
    maxCost: 2999.99,
    warrantyCost: 254.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032780834",
  },
  {
    sku: "warranty-0020016743c6",
    minCost: 3000,
    maxCost: 3999.99,
    warrantyCost: 249.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323032191010",
  },
  {
    sku: "warranty-d797980f4611",
    minCost: 3000,
    maxCost: 3999.99,
    warrantyCost: 289.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032813602",
  },
  {
    sku: "warranty-4b30be31b8fd",
    minCost: 4000,
    maxCost: 4999.99,
    warrantyCost: 269.95,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323032223778",
  },
  {
    sku: "warranty-4ec2549b54ac",
    minCost: 4000,
    maxCost: 4999.99,
    warrantyCost: 299.95,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032846370",
  },
  {
    sku: "warranty-1141af6fa933",
    minCost: 5000,
    maxCost: 7499.99,
    warrantyCost: 299.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323032256546",
  },
  {
    sku: "warranty-b0be476b26d7",
    minCost: 5000,
    maxCost: 7499.99,
    warrantyCost: 329.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032879138",
  },
  {
    sku: "warranty-95415574a1aa",
    minCost: 7500,
    maxCost: 9999.99,
    warrantyCost: 319.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323032289314",
  },
  {
    sku: "warranty-76dc453a7cff",
    minCost: 7500,
    maxCost: 9999.99,
    warrantyCost: 359.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032911906",
  },
  {
    sku: "warranty-2c873ebc92a6",
    minCost: 10000,
    maxCost: 14999,
    warrantyCost: 339.99,
    durationMonths: 48,
    id: "gid://shopify/ProductVariant/39323032322082",
  },
  {
    sku: "warranty-45f5917a4a84",
    minCost: 10000,
    maxCost: 14999,
    warrantyCost: 399.99,
    durationMonths: 60,
    id: "gid://shopify/ProductVariant/39323032944674",
  },
]
