import React, { useState, useEffect } from "react"
import { StoreContext } from "context/store-context"
import CartModal from "components/elements/CartModal"
import { RenderHTML } from "components/elements/LineItem"
import { PlusCircleIcon } from "@heroicons/react/outline"

export function AddWarranty({
  text,
  variantId,
  quantity,
  available,
  offerId,
  itemVariantId,
  duration,
  customAttributes = {},
  productDetails,
  ...props
}) {
  const { addVariantToCart, loading, checkout } = React.useContext(StoreContext)
  const [isCartModalVisible, setIsCartModalVisible] = useState(false)
  const [, setReady] = useState(checkout.ready)
  const [warrantyRed, setWarrantyRed] = useState(false)

  useEffect(() => {
    setReady(checkout.ready)
  }, [checkout.ready])

  function addToCart(e) {
    e.preventDefault()
    const customAttributesList = [
      { key: "_variantId", value: itemVariantId },
      { key: "_warranty_offer_id", value: offerId },
      { key: "_warranty_duration_months", value: duration },
    ]
    for (const [key, value] of Object.entries(customAttributes)) {
      customAttributesList.push({ key: key, value: value })
    }

    //make sure the 60 day warranty duration is added as a custom attribute and the color red.

    addVariantToCart(variantId, quantity, customAttributesList, productDetails)
    setWarrantyRed(true)
  }

  return (
    <>
      <CartModal
        setIsVisible={setIsCartModalVisible}
        isVisible={isCartModalVisible && !loading}
        loading={loading}
      />
      <button
        type="submit"
        className={`font-medium text-xs hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out ${
          available
            ? warrantyRed
              ? " hidden"
              : " text-black"
            : "cursor-not-allowed"
        }`}
        onClick={addToCart}
        disabled={!available}
        {...props}
      >
        <RenderHTML />
        <span>
          <PlusCircleIcon className={"inline w-4 h-4 mr-1"}></PlusCircleIcon>
          {available
            ? text?.length
              ? "Add a 5 year warranty"
              : "Add a 4 year warranty"
            : "Out of Stock"}
        </span>
      </button>
    </>
  )
}
