import React, { FC, Fragment } from "react"
import { XIcon } from "@heroicons/react/solid"
import { Transition, Dialog } from "@headlessui/react"
import Login from "components/layouts/Account/Login"
import { NotificationContext } from "context/notification-context"
import Notification from "components/layouts/Account/Notification"

export interface LoginModalProps {
  isVisible: boolean
  setIsVisible: any
  title: string
}

const LogiModal: FC<LoginModalProps> = ({ isVisible, setIsVisible, title }) => {
  const { notification, showNotification } =
    React.useContext(NotificationContext)

  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className="overflow-y-auto fixed inset-0 z-10"
        onClose={setIsVisible}
      >
        <div className="flex justify-center items-end px-4 pt-4 pb-20 min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block overflow-hidden relative px-6 py-8 w-full text-left align-bottom bg-white rounded-md shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:p-8">
              <div className="flex justify-between items-center">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-bold leading-6 text-idc-title"
                >
                  {title}
                </Dialog.Title>
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md transition duration-300 ease-in-out hover:text-gray-600 focus:outline-none focus:text-gray-600"
                  onClick={() => setIsVisible(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="w-4 h-4" />
                </button>
              </div>

              <div className="mt-6">
                <Transition
                  show={showNotification}
                  enter="transform transition duration-[400ms]"
                  enterFrom="opacity-0 scale-95 "
                  enterTo="opacity-100 scale-100 "
                  leave="transform duration-200 transition ease-in-out"
                  leaveFrom="opacity-100 scale-100 "
                  leaveTo="opacity-0 scale-95 "
                >
                  <Notification notification={notification} />
                </Transition>
                <Login path="" callback={setIsVisible} />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default LogiModal
