import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import bonusPackagesData from "../../../data/bonusPackages.json"

export const FreeGiftMessageItem = ({ idx }) => {
  let staticImageVar

  if (idx === 0) {
    staticImageVar = (
      <StaticImage
        className="flex-shrink-0 mr-6 w-44 h-44"
        objectFit="scale-down"
        src="https://cdn.shopify.com/s/files/1/1201/3604/products/BlackFridayPrizePackagePhotoSetsEditedJPGforUPLOAD_GRAPHICS-19_200x.jpg?v=1669075607"
      />
    )
  } else if (idx === 1) {
    staticImageVar = (
      <StaticImage
        className="flex-shrink-0 mr-6 w-44 h-44"
        objectFit="scale-down"
        src="https://cdn.shopify.com/s/files/1/1201/3604/products/BlackFridayPrizePackagePhotoSetsEditedJPGforUPLOAD_GRAPHICS-17_200x.jpg?v=1669077969"
      />
    )
  } else if (idx === 2) {
    staticImageVar = (
      <StaticImage
        className="flex-shrink-0 mr-6 w-44 h-44"
        objectFit="scale-down"
        src="https://cdn.shopify.com/s/files/1/1201/3604/products/BlackFridayPrizePackagePhotoSetsEditedJPGforUPLOAD_GRAPHICS-8_200x.jpg?v=1669077974"
      />
    )
  } else {
    // Add some placeholder img
    staticImageVar = (
      <StaticImage
        className="flex-shrink-0 mr-6 w-44 h-44"
        objectFit="scale-down"
        src="https://cdn.shopify.com/s/files/1/1201/3604/products/BlackFridayPrizePackagePhotoSetsEditedJPGforUPLOAD_GRAPHICS-6_1200x1200.jpg?v=1669077974"
      />
    )
  }

  return (
    <>
      <div className="flex flex-row mt-5">
        {staticImageVar ? staticImageVar : null}

        <div className="flex flex-col flex-grow justify-between lg:flex-col">
          <div className="flex flex-col justify-between items-start sm:flex-col">
            <h2 className="font-bold">
              {bonusPackagesData[idx]?.title || "Bonus Item"}{" "}
            </h2>
            <p className="text-xs lg:max-w-xs">
              {bonusPackagesData[idx]?.message || ""}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
